export const translations = {
    "english": {
        "languageLabel": "English",
        "loginErrorMessage": "Invalid username or password.",
        "username": "Username",
        "enterUsername": "Enter username",
        "password": "Password",
        "enterPassword": "Enter password",
        "login": "Login",
        "logout": "Logout",
        "overview": "Overview",
        "sensorsCharging": "Sensors that should be charging right now",
        "sensorName": "Sensor name",
        "lastConnected": "Last connected",
        "projectedBatteryLevel": "Projected battery level",
        "batteryLevel": "Battery level",
        "lastBatteryLevel": "Last battery level",
        "patient": "Patient",
        "error": "Error",
        "unknown": "Unknown",
        "timeFormat": "en-GB",
        "lastStatus": "Last status",
        "noSensorsConnected": "No sensors connected",
        "noSensorConnected": "No sensor connected",
        "manageSensors": "Manage sensors",
        "allConnected": "All connected",
        "data": "Data",
        "noDataAvailable": "No Data Available",
        "noInformation": "No Information",
        "loadingData": "Loading data...",
        "condition": "Condition",
        "sensors": "Sensors",
        "sensor": "Sensor",
        "tablets": "Tablets",
        "time": "Time",
        "status": "Status",
        "battery": "Battery",
        "info": "Information",
        "position": "Position",
        "latestUpdates": "Latest updates",
        "earlierUpdates": "Earlier updates",
        "laterUpdates": "Later updates",
        "loadingPatient": "Loading patient...",
        "location": "Location",
        "sessions": "Sessions",
        "sessionBeginDate": "Session begin date",
        "sessionEndDate": "Session end date",
        "patientSettings": "Patient settings",
        "setting": "Setting",
        "value": "Value",
        "epilepsyType": "Epilepsy Type",
        "focal": "Focal",
        "epilepsyFocus": "Epilepsy Focus",
        "left": "Left",
        "right": "Right",
        "noValue": "No value",
        "measurement": "Measurement",
        "allOfSensorsAboveCurrentlyNotConnected": "All of the sensors above are currently not connected to any patient.",
        "all": "All",
        "of": "of",
        "sensorsConnected": "sensors connected",
        "problemWithSensor": "Problem with sensor",
        "problemWithTablet": "Problem with tablet",
        "timeUntilSensorSwitch": "Time until sensor switch",
        "sessionStartedAt": "Session started at",
        "no": "No",
        "connected": "connected",
        "loading": "Loading...",
        "last": "Last",
        "approximateRemainingRunningTime": "Approximate remaining running time",
        "approximateChargeLevel": "Approximate charge level",
        "somethingWentWrong": "Something went wrong",
        "editCondition": "Edit condition",
        "functional": "Functional",
        "defective": "Defective",
        "mac": "MAC",
        "currentlyNoFreeAndChargedSensorAvailable": "There is currently no free and charged sensor available.",
        "isAvailableButNoInformationOnBattery": "is available but there is no information about its battery level.",
        "isAvailableButBatteryMaybeLow": "is available but its battery level might be too low.",
        "dontForgetToChargeOtherSensor": "If there is a sensor currently connected to this patient, don't forget to charge it!",
        "youCanPickUpSensor": "You can pick up sensor",
        "andConnectItToThisPatient": "and connect it to this patient.",
        "patientInformationAndSettings": "Patient Information & Settings",
        "noNeedToSwitchSensorInfo": "According to the battery level there is currently no need to switch the sensor. Only switch it if there is a problem with the sensor itself and mark it as defective under the<i>Sensors</i> tab.",
        "sensorSwitchingOptions": "Sensor switching options",
        "availableSensorsOrderedByBatteryLevel": "All available sensors ordered by battery level",
        "needsToBeChanged": "needs to be changed.",
        "sensorSettings": "Sensor settings",
        "sensorUncomfortInformation": "Patients can decide against the use of certain sensors, due to uncomfort. In that case please note here which sensors are not going to be worn by the patient. The following sensors will be worn by the patient:",
        "allSensorsDisabled": "All sensors disabled.",
        "hand": "Hand",
        "foot": "Foot",
        "sensorHasNoChargeLeft": "Sensor probably has no charge left. It needs to be charged immediately.",
        "heartrate": "Heartrate",
        "perfusionIndex": "Perfusion Index",
        "quality": "Quality",
        "respirationRate": "Respiration Rate",
        "oxygenSaturation": "Oxygen Saturation",
        "temperature": "Temperature",
        "type": "Type",
        "bodyPosition": "Body position",
        "connectedAt": "Connected at",
        "disconnectedAt": "Disconnected at",
        "sensorHistory": "Sensor history",
        "notificationCenter": "Notification Center",
        "noNotifications": "No notifications.",
        "topic": "Topic",
        "message": "Message",
        "dismiss": "Dismiss",
        "pickUpASensorThatIsFullyCharged": "Please pick up a sensor that is fully charged and connect it to the patient.",
        "documents": "Documents",
        "loggedInAs": "Logged in as"
    },
    "german": {
        "languageLabel": "Deutsch",
        "loginErrorMessage": "Falscher Benutzername oder Passwort.",
        "username": "Benutzername",
        "enterUsername": "Benutzername eingeben",
        "password": "Passwort",
        "enterPassword": "Passwort eingeben",
        "login": "Anmelden",
        "logout": "Ausloggen",
        "overview": "Überblick",
        "sensorsCharging": "Sensoren die gerade geladen werden sollten",
        "sensorName": "Sensorenname",
        "lastConnected": "Zuletzt verbunden",
        "projectedBatteryLevel": "Prognostizierter Akkustand",
        "batteryLevel": "Akkustand",
        "lastBatteryLevel": "Letzter Akkustand",
        "patient": "Patient",
        "error": "Fehler",
        "unknown": "Unbekannt",
        "timeFormat": "de-DE",
        "lastStatus": "Letzter Status",
        "noSensorsConnected": "Keine Sensoren verbunden",
        "noSensorConnected": "Kein Sensor verbunden",
        "manageSensors": "Sensoren verwalten",
        "allConnected": "Alle verbunden",
        "noDataAvailable": "Keine Daten verfügbar",
        "noInformation": "Keine Information",
        "data": "Daten",
        "loadingData": "Lade Daten...",
        "condition": "Zustand",
        "sensors": "Sensoren",
        "sensor": "Sensor",
        "tablets": "Tablets",
        "time": "Zeit",
        "status": "Status",
        "battery": "Batterie",
        "info": "Information",
        "position": "Position",
        "latestUpdates": "Letzte Updates",
        "earlierUpdates": "Frühere Updates",
        "laterUpdates": "Spätere Updates",
        "loadingPatient": "Lade Patient...",
        "location": "Standort",
        "sessions": "Sessions",
        "sessionBeginDate": "Session Startzeitpunkt",
        "sessionEndDate": "Session Endzeitpunkt",
        "patientSettings": "Patient Einstellungen",
        "setting": "Einstellung",
        "value": "Wert",
        "epilepsyType": "Epilepsie Typ",
        "focal": "Focal",
        "epilepsyFocus": "Epilepsie Fokus",
        "left": "Links",
        "right": "Rechts",
        "noValue": "Kein Wert",
        "measurement": "Messwert",
        "allOfSensorsAboveCurrentlyNotConnected": "Alle der oben angezeigten Sensoren sind gerade mit keinem Patienten verbunden.",
        "all": "Alle",
        "of": "von",
        "sensorsConnected": "Sensoren verbunden",
        "problemWithSensor": "Problem mit Sensor",
        "problemWithTablet": "Problem mit Tablet",
        "timeUntilSensorSwitch": "Zeit bis Sensorwechsel",
        "sessionStartedAt": "Session gestartet um",
        "no": "Kein",
        "connected": "verbunden",
        "loading": "Lade...",
        "last": "Letzter",
        "approximateRemainingRunningTime": "Geschätzte verbleibende Akkulaufzeit",
        "approximateChargeLevel": "Geschätzer Batterie-Ladestand",
        "somethingWentWrong": "Etwas ist falschgelaufen",
        "editCondition": "Zustand bearbeiten",
        "functional": "Funktional",
        "defective": "Defekt",
        "mac": "MAC",
        "currentlyNoFreeAndChargedSensorAvailable": "Es ist derzeit kein geladener Sensor verfügbar.",
        "isAvailableButNoInformationOnBattery": "ist verfügbar, aber es gibt keine Information über den Akkustand.",
        "isAvailableButBatteryMaybeLow": "ist verfübar, aber der Akkustand könnte zu niedrig sein.",
        "dontForgetToChargeOtherSensor": "Falls vorher schon ein anderer Sensor mit diesem Patienten verbunden war, vergessen Sie diesen nicht aufzuladen!",
        "youCanPickUpSensor": "Nehmen Sie den Sensor",
        "andConnectItToThisPatient": "und verbinden Sie ihn mit dem Patienten.",
        "patientInformationAndSettings": "Patienten Informationen & Einstellungen",
        "noNeedToSwitchSensorInfo": "Dem Akkustand zufolge besteht im Moment keine Notwendigkeit den Sensor zu wechseln. Wechseln Sie den Sensor nur falls es ein Problem mit dem Sensor selbst besteht und markieren Sie ihn als defekt in der Liste der Sensoren.",
        "sensorSwitchingOptions": "Sensor Wechseloptionen",
        "availableSensorsOrderedByBatteryLevel": "Alle verfügbaren Sensoren, geordnet nach Akkustand",
        "needsToBeChanged": "muss gewechselt werden.",
        "sensorSettings": "Sensor Einstellungen",
        "sensorUncomfortInformation": "Falls das Tragen eines gewissen Sensors zu unbequem ist, kann der Patient entscheiden diesen Sensor nicht weiter zu tragen. In diesem Fall sollte dies hier vermerkt werden. Folgende Sensoren werden von diesem Patienten getragen:",
        "allSensorsDisabled": "Alle Sensoren deaktiviert.",
        "hand": "Hand",
        "foot": "Fuß",
        "sensorHasNoChargeLeft": "Sensor probably has no charge left. It needs to be charged immediately.",
        "heartrate": "Herzfrequenz",
        "perfusionIndex": "Perfusion Index",
        "quality": "Datenqualität",
        "respirationRate": "Atemfrequenz",
        "oxygenSaturation": "Blutsauerstoffsättigung",
        "temperature": "Temperatur",
        "type": "Typ",
        "bodyPosition": "Körperposition",
        "connectedAt": "Verbunden um",
        "disconnectedAt": "Getrennt um",
        "sensorHistory": "Sensorenhistorie",
        "notificationCenter": "Notification Center",
        "noNotifications": "Keine Mitteilungen.",
        "topic": "Thema",
        "message": "Nachricht",
        "dismiss": "Ausblenden",
        "pickUpASensorThatIsFullyCharged": "Nehmen Sie einen Sensor der vollständig aufgeladen ist und verbinden Sie ihn mit dem Patienten.",
        "documents": "Dokumente",
        "loggedInAs": "Eingeloggt als"
    }
}

export const supportedLanguages = ["english", "german"]
