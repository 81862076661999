import { translations } from '../helpers/translations';
import { Button, Row, Spinner } from 'react-bootstrap';
import { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import SensorCard from './sensorCards/SensorCard';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PluxGraph from './graphs/PluxGraph';
import CosinussGraph from './graphs/CosinussGraph';
import MetaMotionGraph from './graphs/MetaMotionGraph';
import SmellGraph from './graphs/SmellGraph';

export default function PatientBox(props) {
    const [patient, setPatient] = useState(null);
    const [loading, setLoading] = useState(true);
    const tl = translations[useContext(LanguageContext).language];
    const navigate = useNavigate();
    const sensors = {}

    useEffect(() => {
        async function updatePatient() {
            axios.get('/api/sessions', {
                params: {
                    epilepsiaId: props.epilepsia_id
                }
            }).then(async res => {
                const patient = res.data.sessions[0];
                axios.get('/api/sensors', {
                    params: {
                        epilepsiaId: props.epilepsia_id
                    }
                }).then(res => {
                    patient.current_sensors = res.data.sensors;
                    setPatient(patient);
                    setLoading(false);
                })
            });
        }
        updatePatient();
        const intervalID = setInterval(() => {
            updatePatient();
        }, 10_000);
        return () => {
            clearInterval(intervalID);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function putSensorsInObject() {
        if (patient == null) {
            return;
        }
        for (const sensor of patient.current_sensors) {
            if (sensor.type === 'metamotion') {
                sensors[sensor.type + '_' + sensor.bodyPosition] = sensor;
            } else {
                sensors[sensor.type] = sensor;
            }
        }
    }

    function goToPatient(e) {
        navigate('/patient/' + patient.epilepsia_id);
    }

    putSensorsInObject();

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    } else if (patient.excluded_sensors != null && patient.excluded_sensors.length === 5) {
        return <div>
            <Row>
                <h3>{patient.epilepsia_id}</h3>
                <h3>{tl.allSensorsDisabled}</h3>
                <Button onClick={goToPatient}>{tl.patientInformationAndSettings}</Button>
            </Row>
        </div>
    } else {
        return (
            <div>
                <Row>
                    <h3>{patient.epilepsia_id}</h3>
                    {patient.excluded_sensors != null && patient.excluded_sensors.includes('plux') ?
                        <></>
                        :
                        <SensorCard type='Plux' loading={loading} sensor={sensors.plux} />
                    }
                    {patient.excluded_sensors != null && patient.excluded_sensors.includes('metamotion_hand') ?
                        <></>
                        :
                        <SensorCard type={'Metamotion ' + tl.hand} loading={loading} sensor={sensors.metamotion_hand} />
                    }
                    {patient.excluded_sensors != null && patient.excluded_sensors.includes('metamotion_foot') ?
                        <></>
                        :
                        <SensorCard type={'Metamotion ' + tl.foot} loading={loading} sensor={sensors.metamotion_foot} offset={sensors.metamotion_hand == null ? 1 : 0} />
                    }
                    {patient.excluded_sensors != null && patient.excluded_sensors.includes('smell') ?
                        <></>
                        :
                        <SensorCard type='Smell' loading={loading} sensor={sensors.smell} />
                    }
                    {patient.excluded_sensors != null && patient.excluded_sensors.includes('cosinuss') ?
                        <></>
                        :
                        <SensorCard type='Cosinuss' loading={loading} sensor={sensors.cosinuss} epilepsiaId={props.epilepsia_id} />
                    }
                </Row >
                <hr />
                {/*
                Graphs commented out for now, need more work since new data structure
                <Row>
                    {patient.excluded_sensors != null && patient.excluded_sensors.includes('plux') ?
                        <></>
                        :
                        <PluxGraph sensor={sensors.plux} patient={patient} />
                    }
                    {patient.excluded_sensors != null && patient.excluded_sensors.includes('metamotion_hand') ?
                        <></>
                        :
                        <MetaMotionGraph sensor={sensors.metamotion_hand} patient={patient} />
                    }
                    {patient.excluded_sensors != null && patient.excluded_sensors.includes('metamotion_foot') ?
                        <></>
                        :
                        <MetaMotionGraph sensor={sensors.metamotion_foot} patient={patient} />
                    }
                    {patient.excluded_sensors != null && patient.excluded_sensors.includes('smell') ?
                        <></>
                        :
                        <SmellGraph sensor={sensors.smell} patient={patient} />
                    }
                    {patient.excluded_sensors != null && patient.excluded_sensors.includes('cosinuss') ?
                        <></>
                        :
                        <CosinussGraph sensor={sensors.cosinuss} patient={patient} />
                    }
                </Row >
                */}

                <Button onClick={goToPatient}>{tl.patientInformationAndSettings}</Button>
            </div >
        )
    }

}
