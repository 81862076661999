import { translations as tl } from "./translations";

export function getTokenCookie() {
    return document.cookie
        .split("; ")
        .find((row) => row.startsWith("epidash_token="))
        ?.split("=")[1];
}

export function getUsernameCookie() {
    return document.cookie
        .split("; ")
        .find((row) => row.startsWith("epidash_username="))
        ?.split("=")[1];
}

export function formatMinutes(minutes) {
    let hours = Math.floor(minutes / 60);
    let remainderMin = Math.floor(minutes % 60);
    if (hours === 0) {
        return remainderMin + 'min';
    } else {
        return hours + 'h ' + remainderMin + 'min';
    }
}

export function getDateFromTimestamp(timeFormat, ts) {
    if (timeFormat == null || ts == null) return null;
    return new Intl.DateTimeFormat(timeFormat, { dateStyle: 'medium', timeStyle: 'medium' }).format(new Date(ts));
}

export function formatTime(timeFormat, update) {
    if (update != null) {
        const ts = update.timestamp;
        const date = getDateFromTimestamp(timeFormat, ts);
        update.date = date;
    }
}

export function orderSensorsByChargeLevel(sensors) {
    if (sensors != null) {
        sensors.sort((s1, s2) => {
            if (s1 == null && s2 == null) {
                return -1;
            } else if (s1 == null && s2 != null) {
                return 1;
            } else if (s1 != null && s2 == null) {
                return -1;
            } else {
                return s2.approximatedChargeLevel - s1.approximatedChargeLevel;
            }
        });
    }
}

export function getLowestRunningSensor(patient) {
    let lowest = null;
    for (const sensor of patient.current_sensors) {
        if (lowest == null) {
            lowest = sensor;
            continue;
        }
        if (sensor.latestUpdate == null) {
            continue;
        }
        if (sensor.remainingRunningTime < lowest.remainingRunningTime) {
            lowest = sensor;
        }
    }
    return lowest;
}

export function getLastUsedCosinussSize(epilepsiaId) {

}

export function getRemainingRunningTimeInfo(sensor) {
    if (sensor == null || sensor.remainingRunningTime == null) {
        return tl.noInformation;
    } else if (sensor.remainingRunningTime <= 0) {
        return tl.sensorHasNoChargeLeft;
    } else {
        return formatMinutes(sensor.remainingRunningTime);
    }
}

export function getSensorFromPatient(patient, type) {
    for (const sensor of patient.current_sensors) {
        const currType = sensor.name.charAt(0).toString();
        if (currType === type) {
            return sensor;
        }
    }
    return null;
}

export function getVariantForSensor(sensor) {
    if (sensor == null ||
        sensor.remainingRunningTime == null ||
        sensor.remainingRunningTime < 30 ||
        sensor.latestUpdate == null ||
        sensor.latestUpdate == null ||
        sensor.latestUpdate.status === 'disconnected' ||
        sensor.latestUpdate.status === 'error') {
        return 'danger';
    }
    return 'success';
}

//maybe not totally accurate, rewrite if necessary
export function formatData(joinInto, ...arrays) {
    if (joinInto == null) {
        return {};
    }

    const indexMap = {};

    for (const e of joinInto) {
        indexMap[e._time] = e;
        e[e._field] = e._value;
        e.fields = [e._field];
    }

    for (const a of arrays) {
        if (a == null) continue;
        for (const e of a) {
            const joinKey = e._time;
            if (joinKey in indexMap) {
                const o = joinInto.find(o => o._time === e._time);
                o[e._field] = e._value;
                o.fields.push(e._field);
            }
        }
    }
    return joinInto;
}

export function getTimeUntilSensorSwitch(patient, tl) {
    const lowestSensor = getLowestRunningSensor(patient);
    if (lowestSensor == null || lowestSensor.remainingRunningTime == null || lowestSensor.remainingRunningTime < 0) {
        return tl.noInformation;
    } else {
        return formatMinutes(lowestSensor.remainingRunningTime);
    }
}

/*export async function enrichWithLatestUpdates(sensor, sessionId, addApproximateChargeLevel, addRemainingBatteryLife, limit, timeFormat) {
    const params = {
        limit: limit
    }
    if (sessionId != null) {
        params.sessionId = sessionId;
    }
    return axios.get('/api/sensorUpdates/' + sensor.mac, { params }).then(res => {
        sensor.latestUpdates = res.data.updates;
        for (const update of sensor.latestUpdates) {
            formatTime(timeFormat, update);
        }
        if (sensor.latestUpdates.length > 0 && addApproximateChargeLevel) {
            sensor.approximatedChargeLevel = calculateChargeLevel(sensor);
        } else {
            sensor.approximatedChargeLevel = null;
        }
        if (sensor.latestUpdates.length > 0 && addRemainingBatteryLife) {
            sensor.remainingRunningTime = calculateRemainingRunningTime(sensor);
        } else {
            sensor.remainingRunningTime = null;
        }
    });
}*/

export const lineColors = ['#FF0000', '#0000FF', '#008000', '#2986cc', '#800080'];
export const availableLocations = ['NMC', 'MC4'];
