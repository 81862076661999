import { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { translations } from '../helpers/translations';
import { LanguageContext } from '../contexts/LanguageContext';
import { orderSensorsByChargeLevel } from '../helpers/helperFunctions';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { LocationContext } from '../contexts/LocationContext';

export default function NewSensorAlert(props) {
    const tl = translations[useContext(LanguageContext).language];
    /*
    const location = useContext(LocationContext).location;
    const [newSensorSuggestions, setNewSensorSuggestions] = useState(null);
    //offset used for metamotions, because sometimes two different sensors need to be suggested
    const position = props.offset;
    const size = props.size;
    */

    /*
    useEffect(() => {
        async function updateSensorSuggestions() {
            axios.get('/api/sensors', {
                params: {
                    condition: 'functional',
                    status: 'disconnected',
                    type: props.type,
                    location: location,
                    size: size
                }
            }).then(res => {
                setNewSensorSuggestions(res.data.sensors);
            });
        }
        updateSensorSuggestions();
        const intervalID = setInterval(() => {
            updateSensorSuggestions();
        }, 10_000);
        return () => {
            clearInterval(intervalID);
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps
    */

    //orderSensorsByChargeLevel(newSensorSuggestions);

    return <div className="m-2 alert alert-secondary" role="alert">
        {tl.pickUpASensorThatIsFullyCharged}
    </div>
    /*
    if (newSensorSuggestions == null) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    } else if (newSensorSuggestions.length === 0 || newSensorSuggestions[position] == null) {
        return <div className="m-2 alert alert-secondary" role="alert">
            {tl.currentlyNoFreeAndChargedSensorAvailable}
        </div>
    } else if (newSensorSuggestions[position].approximatedChargeLevel == null) {
        return (
            <div className="m-2 alert alert-secondary" role="alert">
                <div>
                    {tl.sensor} {newSensorSuggestions[position].name} {tl.isAvailableButNoInformationOnBattery}
                </div>
            </div>
        )
    } else if (newSensorSuggestions[position].remainingRunningTime < 90) {
        return (
            <div className="m-2 alert alert-secondary" role="alert">
                <div>
                    {tl.sensor} {newSensorSuggestions[position].name} {tl.isAvailableButBatteryMaybeLow}
                </div>
                <div>
                    {tl.approximatedChargeLevel} {newSensorSuggestions[position].name}: {newSensorSuggestions[position].approximatedChargeLevel}%
                </div>
            </div>
        )
    } else {
        return (
            <div className="m-2 alert alert-secondary" role="alert">
                <div>
                    {tl.youCanPickUpSensor} {newSensorSuggestions[position].name} {tl.andConnectItToThisPatient}
                </div>
                <div>
                    {tl.dontForgetToChargeOtherSensor}
                </div>
            </div>
        )
    }
    */

}
