import React from 'react';

const Documents = () => {
    const documentList = [
        'epidash.pdf',
        'sensorik.pdf',
        'sensorwechsel.pdf',
    ];

    return (
        <div>
            <h1>Documents</h1>
            <ul>
                {documentList.map((document, index) => (
                    <li key={index}>
                        <a href={`/documents/${document}`} target="_blank" rel="noopener noreferrer">
                            {document}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Documents;