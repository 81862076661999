import { useContext } from 'react';
import { translations } from '../../helpers/translations';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { LanguageContext } from '../../contexts/LanguageContext';
import { LocationContext } from '../../contexts/LocationContext';
import axios from 'axios';
import { availableLocations } from '../../helpers/helperFunctions';

function LocationDropdown(props) {
    const { location, setLocation } = useContext(LocationContext);
    const tl = translations[useContext(LanguageContext).language];

    async function handleLocationChange(newLocation) {
        if (location === newLocation) return;
        await axios.post('/api/settings', {
            preferredLocation: newLocation
        });
        setLocation(newLocation);
    }

    return (
        <Dropdown onSelect={handleLocationChange}>
            <DropdownButton
                id="locationDropdown"
                title={location}
                variant="secondary"
            >
                {availableLocations.map((location, index) => (
                    <Dropdown.Item eventKey={location} key={index}>
                        {location}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </Dropdown>
    )

}

export default LocationDropdown;
