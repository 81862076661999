import { useContext } from 'react';
import { translations, supportedLanguages } from '../../helpers/translations';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { LanguageContext } from '../../contexts/LanguageContext';
import axios from 'axios';

function LanguageDropdown(props) {
    const { language, setLanguage } = useContext(LanguageContext);
    const tl = translations[language];
    const loggedIn = props.loggedIn;

    //is also called when language gets changed in login screen, request then of course returns 401, maybe fix later, but not a problem right now
    async function handleLanguageChange(newLanguage) {
        if (language === newLanguage) return;
        if (loggedIn) {
            await axios.post('/api/settings', {
                preferredLanguage: newLanguage
            });
        }
        setLanguage(newLanguage);
    }

    return (
        <Dropdown onSelect={handleLanguageChange}>
            <DropdownButton
                id="languageDropdown"
                title={tl.languageLabel}
                variant="secondary"
            >
                {supportedLanguages.map((language, index) => (
                    <Dropdown.Item eventKey={language} key={index}>
                        {translations[language].languageLabel}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </Dropdown>
    )

}

export default LanguageDropdown;
