import { useState } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { LocationContext } from "../contexts/LocationContext";
import { RoleContext } from "../contexts/RoleContext";

export default function Wrapper(props) {
    const [language, setLanguage] = useState("english");
    const [location, setLocation] = useState("");
    const [roles, setRoles] = useState([]);
    const languageValue = { language, setLanguage };
    const locationValue = { location, setLocation };
    const roleValue = { roles, setRoles };

    return (
        <LanguageContext.Provider value={languageValue}>
            <LocationContext.Provider value={locationValue}>
                <RoleContext.Provider value={roleValue}>
                    {props.children}
                </RoleContext.Provider>
            </LocationContext.Provider>
        </LanguageContext.Provider>
    )
}
