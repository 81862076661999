import { useState, useEffect, useContext } from 'react';
import { Table, Spinner } from 'react-bootstrap';
import { getDateFromTimestamp } from "../helpers/helperFunctions";
import axios from 'axios';
import { translations } from '../helpers/translations';
import { LanguageContext } from '../contexts/LanguageContext';
import { LocationContext } from '../contexts/LocationContext';

export default function SessionList(props) {
    const tl = translations[useContext(LanguageContext).language];
    const location = useContext(LocationContext).location;
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState([]);


    useEffect(() => {
        async function updateSessions() {
            axios.get('/api/sessions', {
                params: {
                    
                }
            }).then(res => {
                const currentTimestamp = Date.now();
                const sessions = res.data.sessions;
                sessions.map(item => {
                    if (item["status"] === "running") {
                        item["end_ts"] = currentTimestamp;
                    }
                    return item;
                });
                setSessions(sessions);
                setLoading(false);
            });
        }
        updateSessions();
        const intervalID = setInterval(() => {
            updateSessions();
        }, 10_000);
        return () => {
            clearInterval(intervalID);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Bsp Link for Grafana Dashboard
    //https://dashboard.epilepsia.fivesquare.dev/d/ecc9bcfe-78ca-4545-8cb5-9e0f78c0172d/epilepsia-prod-analysis?orgId=1&from=1708611600000&to=1708612785000&var-participant_ID=1H2UH1&var-plux_mode=plux-mem-offline
    function buildLink(epilepsia_id, begin_ts, end_ts) {
        const link = `https://dashboard.epilepsia.fivesquare.dev/d/ecc9bcfe-78ca-4545-8cb5-9e0f78c0172d/epilepsia-prod-analysis?orgId=1&from=${begin_ts}&to=${end_ts}&var-participant_ID=${epilepsia_id}&var-plux_mode=plux-mem-offline&var-spo2_window=1s`;
        return link;
    }

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    } else {
        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Epilepsia ID</th>
                            <th>{tl.location}</th>
                            <th>{tl.sessionBeginDate}</th>
                            <th>{tl.sessionEndDate}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sessions.map((session, index) => (
                            <tr key={index}>
                                <td>
                                    <a href={buildLink(session.epilepsia_id, session.begin_ts, session.end_ts)}>
                                    {session.epilepsia_id}
                                    </a>
                                </td>
                                <td>{session.location}</td>
                                <td>{getDateFromTimestamp(tl.timeFormat, session.begin_ts)}</td>
                                <td>{getDateFromTimestamp(tl.timeFormat, session.end_ts)}</td>     
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }


    
}