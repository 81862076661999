import React, { useContext, useEffect, useState } from 'react';
import { translations } from '../../helpers/translations';
import { LanguageContext } from '../../contexts/LanguageContext';
import axios from 'axios';
import { FormCheck } from 'react-bootstrap';

export default function ExcludeSensors(props) {
    const tl = translations[useContext(LanguageContext).language];
    const patient = props.patient;
    const [pluxChecked, setPluxChecked] = useState(true);
    const [smellChecked, setSmellChecked] = useState(true);
    const [metamotionHandChecked, setMetamotionHandChecked] = useState(true);
    const [metamotionFootChecked, setMetamotionFootChecked] = useState(true);
    const [cosinussChecked, setCosinussChecked] = useState(true);

    useEffect(() => {
        if (patient != null && patient.excluded_sensors != null) {
            for (const sensor of patient.excluded_sensors) {
                switch (sensor) {
                    case 'plux': setPluxChecked(false); break;
                    case 'metamotion_hand': setMetamotionHandChecked(false); break;
                    case 'metamotion_foot': setMetamotionFootChecked(false); break;
                    case 'cosinuss': setCosinussChecked(false); break;
                    case 'smell': setSmellChecked(false); break;
                    default: break;
                }
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function handleExcludedSensorsSwitch(event) {
        const unchecked = getUncheckedSensors();
        switch (event.target.id) {
            case 'plux':
                //if a switch is currently checked it is now being unchecked
                //no need to check if index isnt -1, because all values of the array are known
                if (pluxChecked) {
                    unchecked.push(event.target.id);
                } else {
                    const idx = unchecked.indexOf('plux');
                    unchecked.splice(idx, 1);
                }
                setPluxChecked(!pluxChecked);
                break;
            case 'metamotion_hand':
                if (metamotionHandChecked) {
                    unchecked.push(event.target.id);
                } else {
                    const idx = unchecked.indexOf('metamotion_hand');
                    unchecked.splice(idx, 1);
                }
                setMetamotionHandChecked(!metamotionHandChecked);
                break;
            case 'metamotion_foot':
                if (metamotionFootChecked) {
                    unchecked.push(event.target.id);
                } else {
                    const idx = unchecked.indexOf('metamotion_foot');
                    unchecked.splice(idx, 1);
                }
                setMetamotionFootChecked(!metamotionFootChecked);
                break;
            case 'cosinuss':
                if (cosinussChecked) {
                    unchecked.push(event.target.id)
                } else {
                    const idx = unchecked.indexOf('cosinuss');
                    unchecked.splice(idx, 1);
                }
                setCosinussChecked(!cosinussChecked);
                break;
            case 'smell':
                if (smellChecked) {
                    unchecked.push(event.target.id);
                } else {
                    const idx = unchecked.indexOf('smell');
                    unchecked.splice(idx, 1);
                }
                setSmellChecked(!smellChecked);
                break;
            default: break;
        }
        axios.patch('/api/session/' + patient.epilepsia_id, {
            excluded_sensors: unchecked
        });
    }

    function getUncheckedSensors() {
        const unchecked = [];
        if (!pluxChecked) unchecked.push('plux');
        if (!metamotionHandChecked) unchecked.push('metamotion_hand');
        if (!metamotionFootChecked) unchecked.push('metamotion_foot');
        if (!smellChecked) unchecked.push('smell');
        if (!cosinussChecked) unchecked.push('cosinuss');
        return unchecked;
    }

    return (
        <div>
            <FormCheck
                id='plux'
                type="switch"
                checked={pluxChecked}
                onChange={handleExcludedSensorsSwitch}
                label='Plux'
            />
            <FormCheck
                id='smell'
                type="switch"
                checked={smellChecked}
                onChange={handleExcludedSensorsSwitch}
                label='Smell'
            />
            <FormCheck
                id='metamotion_hand'
                type="switch"
                checked={metamotionHandChecked}
                onChange={handleExcludedSensorsSwitch}
                label='Metamotion Hand'
            />
            <FormCheck
                id='metamotion_foot'
                type="switch"
                checked={metamotionFootChecked}
                onChange={handleExcludedSensorsSwitch}
                label='Metamotion Foot'
            />
            <FormCheck
                id='cosinuss'
                type="switch"
                checked={cosinussChecked}
                onChange={handleExcludedSensorsSwitch}
                label='Cosinuss'
            />
        </div>
    )
}