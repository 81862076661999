import React, { useContext, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import LanguageDropdown from './LanguageDropdown.jsx';
import LocationDropdown from './LocationDropdown.jsx';
import { translations } from '../../helpers/translations.js';
import { LanguageContext } from '../../contexts/LanguageContext.js';
import { RoleContext } from '../../contexts/RoleContext.js';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

function Navigation(props) {
    const tl = translations[useContext(LanguageContext).language];
    const roles = useContext(RoleContext).roles;
    const navigate = useNavigate();

    async function handleLogout() {
        const res = await axios.post('/api/logout');
        console.log(res);
        if (res.data.logoutSuccessful) {
            navigate('/');
        }
    }

    //fix logged in as column
    return (
        <nav className="navbar navbar-expand-lg navbar-light border-bottom">
            <Row className="row align-items-center justify-content-end w-100">
                <Col>
                    <Link className="navbar-brand" to={'/'}>EPILEPSIA</Link>
                </Col>
                <Col className="d-flex align-items-center justify-content-start align-self-end">
                    <h6>{tl.loggedInAs}: {Cookies.get("epidash_username")}</h6>
                </Col>
                <Col></Col>
                {false &&
                    <Col className="d-flex justify-content-end">
                        <Button className="mx-2 btn-secondary" disabled>{tl.loggedInAs}: {Cookies.get("epidash_username")}</Button>
                    </Col>
                }
                <Col className="d-flex justify-content-end">
                    {
                        !roles.includes('hospital') &&
                        <div className='mx-2'>
                            <LocationDropdown />
                        </div>
                    }
                    <div className='mx-2'>
                        <LanguageDropdown loggedIn={true} />
                    </div>
                    <Button className="mx-2" onClick={handleLogout}>{tl.logout}</Button>
                </Col>
            </Row>
        </nav >
    )

}

export default Navigation;