import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Container from 'react-bootstrap/Container';
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import Error from './pages/Error/Error';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Sensor from './pages/Sensor/Sensor';
import Tablet from './pages/Tablet/Tablet';
import Patient from './pages/Patient/Patient';
import Wrapper from './components/Wrapper';
import axios from 'axios';
import { getTokenCookie, getUsernameCookie } from './helpers/helperFunctions';

axios.defaults.withCredentials = true
axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response?.status === 401) {
    //window.location.reload();
  }
  return error;
});

async function checkAuth() {
  const token = getTokenCookie();
  const username = getUsernameCookie();
  try {
    if (token != null && username != null) {
      const encodedToken = encodeURIComponent(token);
      const res = await axios.get('/api/checkSession/' + username + '/' + encodedToken);
      return res.data.sessionValid;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

async function authorizedRouteLoader({ request }) {
  const authorized = await checkAuth();
  if (authorized) {
    return null;
  } else {
    return redirect('/login');
  }
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,
    loader: authorizedRouteLoader
  },
  {
    path: "/sensor/:mac",
    element: <Sensor />,
    errorElement: <Error />,
    loader: authorizedRouteLoader
  },
  {
    path: "/tablet/:mac",
    element: <Tablet />,
    errorElement: <Error />,
    loader: authorizedRouteLoader
  },
  {
    path: "/patient/:epilepsiaId",
    element: <Patient />,
    errorElement: <Error />,
    loader: authorizedRouteLoader
  },
  {
    path: "/error",
    element: <Error />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Container className='App'>
      <Wrapper>
        <RouterProvider router={router} />
      </Wrapper>
    </Container>
  </React.StrictMode>
);