import { useState, useEffect, useContext } from 'react';
import { Table, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { translations } from '../helpers/translations';
import { LanguageContext } from '../contexts/LanguageContext';
import { LocationContext } from '../contexts/LocationContext';

export default function ManageTablets(props) {
    const tl = translations[useContext(LanguageContext).language];
    const location = useContext(LocationContext).location;
    const [tablets, setTablets] = useState(null);

    useEffect(() => {
        async function loadTablets() {
            axios.get('/api/tablets/', {
                location: location
            }).then(res => {
                setTablets(res.data.tablets);
            });
        }
        loadTablets();
        const intervalID = setInterval(() => {
            loadTablets();
        }, 10_000);
        return () => {
            clearInterval(intervalID);
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    if (tablets == null) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )
    } else {
        return (
            <div>
                <h3>{tl.tablets}</h3>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{tl.mac}</th>
                            <th>{tl.lastStatus}</th>
                            <th>{tl.position}</th>
                            <th>Cosinuss Gateway</th>
                            <th>Cosinuss Person ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tablets.map((tablet, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{tablet.mac}</td>
                                <td>{tablet.status}</td>
                                <td>{tablet.pos}</td>
                                <td>{tablet.cosinuss_gw}</td>
                                <td>{tablet.cosinuss_pid}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

};