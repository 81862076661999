//battery life and charging time in hours
export const sensorInformation = {
    'plux': {
        imageURL: '/drawable/plux.png'
    },
    'metamotion': {
        imageURL: '/drawable/metamotion.png'
    },
    'cosinuss': {
        imageURL: '/drawable/cosinuss.png'
    },
    'smell': {
        imageURL: '/drawable/smelly.png'
    }
}
