import { translations } from '../../helpers/translations';
import { ListGroup, Card, Col, Spinner } from 'react-bootstrap';
import { getRemainingRunningTimeInfo, getVariantForSensor, getLastUsedCosinussSize } from '../../helpers/helperFunctions';
import NewSensorAlert from '../NewSensorAlert';
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import './SensorCard.css';
import { sensorInformation } from '../../helpers/sensorInformation';

export default function SensorCard(props) {
    const tl = translations[useContext(LanguageContext).language];
    const offset = props.offset == null ? 0 : props.offset;
    const sensor = props.sensor;
    const loading = props.loading;
    const cleanedTypeString = props.type.split(' ')[0].toLowerCase();
    let size = sensor;
    if (size == null && props.type === 'cosinuss') {
        size = getLastUsedCosinussSize(props.epilepsiaId);
    }

    if (sensor == null && !loading) {
        return (
            <Col md={6} lg={4}>
                <Card className='m-2 border-danger'>
                    <Card.Img className='card-img' variant="top" src={sensorInformation[cleanedTypeString].imageURL} />
                    <Card.Body>
                        <Card.Title><b>{tl.no} {props.type} {tl.connected}</b></Card.Title>
                        <NewSensorAlert size={null} offset={offset} type={cleanedTypeString}></NewSensorAlert>
                    </Card.Body>
                </Card>
            </Col>
        )
    } else if (sensor == null && loading) {
        return (
            <Col md={6} lg={4}>
                <Card className='m-2'>
                    <Card.Img className='card-img' variant="top" src={sensorInformation[cleanedTypeString].imageURL} />
                    <Card.Body>
                        <Card.Title>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Card.Title>
                    </Card.Body>
                </Card>
            </Col>
        )
    } else if (sensor != null) {
        const variant = getVariantForSensor(sensor);
        return (
            <Col md={6} lg={4}>
                <Card className={`m-2 border-${variant}`}>
                    <Card.Img className='card-img' variant="top" src={sensorInformation[sensor.type].imageURL} />
                    <Card.Body>
                        <Card.Title><b>{sensor.name}</b></Card.Title>
                        <ListGroup>
                            <ListGroup.Item>{tl.last} {tl.batteryLevel}: <b>{sensor.latestUpdate != null && sensor.latestUpdate?.battery !== -1 ? sensor.latestUpdate?.battery + '%' : tl.unknown}</b></ListGroup.Item>
                            <ListGroup.Item>{tl.lastStatus}: <b>{sensor.latestUpdate != null ? sensor.latestUpdate.status : tl.unknown}</b></ListGroup.Item>
                            {
                                sensor.remainingRunningTime != null && sensor.remainingRunningTime < 60 ?
                                    <NewSensorAlert size={null} offset={offset} type={cleanedTypeString}></NewSensorAlert>
                                    :
                                    <></>
                            }
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
        )
    } else {
        return (
            <Col md={6} lg={4}>
                <Card className='m-2'>
                    <Card.Body>
                        <Card.Title>{tl.somethingWentWrong}</Card.Title>
                    </Card.Body>
                </Card>
            </Col>
        )
    }

}
