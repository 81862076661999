import { useState, useEffect, useContext } from 'react';
import { Table, Dropdown, DropdownButton, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { translations } from '../helpers/translations';
import { LanguageContext } from '../contexts/LanguageContext';
import { LocationContext } from '../contexts/LocationContext';

export default function ManageSensors(props) {
    const tl = translations[useContext(LanguageContext).language];
    const location = useContext(LocationContext).location;
    const [sensors, setSensors] = useState(null);

    function handleInfoChange(e) {
        const mac = e.target.dataset.mac;
        axios.post('/api/sensorInfo/' + mac, {
            info: e.target.value
        });
    }

    function handleConditionChange(eventKey, e) {
        const mac = eventKey;
        const condition = e.target.dataset.condition;
        axios.post('/api/sensorCondition/' + mac + '/' + condition);
        setSensors(sensors.map(s => {
            if (s.mac === mac) {
                return { ...s, condition: condition };
            } else {
                return s;
            }
        }));
    }

    useEffect(() => {
        async function loadSensors() {
            const res = await axios.get('/api/sensors/', {
                location: location
            });
            setSensors(res.data.sensors);
        }
        loadSensors();
        const intervalID = setInterval(() => {
            loadSensors();
        }, 10_000);
        return () => {
            clearInterval(intervalID);
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    if (sensors == null) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )
    } else {
        return (
            <div>
                <h3>{tl.sensors}</h3>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{tl.sensorName}</th>
                            <th>{tl.lastStatus}</th>
                            <th>{tl.lastBatteryLevel}</th>
                            <th>{tl.editCondition}</th>
                            <th>{tl.info}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sensors.map((sensor, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <Link to={'/sensor/' + sensor.mac}>{sensor.name}</Link>
                                </td>
                                <td>{sensor.latestUpdate ? sensor.latestUpdate.status : tl.unknown}</td>
                                <td>{sensor.latestUpdate ? sensor.latestUpdate.battery + '%' : tl.unknown}</td>
                                <td>
                                    <Dropdown onSelect={handleConditionChange}>
                                        <DropdownButton
                                            title={tl[sensor.condition]}
                                            variant="secondary"
                                        >
                                            <Dropdown.Item eventKey={sensor.mac} data-condition={'functional'}>{tl.functional}</Dropdown.Item>
                                            <Dropdown.Item eventKey={sensor.mac} data-condition={'defective'}>{tl.defective}</Dropdown.Item>
                                        </DropdownButton>
                                    </Dropdown>
                                </td>
                                <td>
                                    <Form.Control data-mac={sensor.mac} onBlur={handleInfoChange} as="textarea" rows={3} defaultValue={sensor.info} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

};