import React, { useContext, useEffect, useState } from 'react';
import { translations } from '../../helpers/translations';
import { LanguageContext } from '../../contexts/LanguageContext';
import axios from 'axios';
import { ListGroup, Dropdown, DropdownButton, Table, FormCheck } from 'react-bootstrap';
import Navigation from '../../components/navigation/Navigation';
import { useParams } from 'react-router-dom';
import { getDateFromTimestamp } from '../../helpers/helperFunctions';
import ExcludeSensors from './ExcludeSensors';
import SensorHistory from './SensorHistory';

export default function Patient(props) {
    const tl = translations[useContext(LanguageContext).language];
    const [patient, setPatient] = useState(null);
    const { epilepsiaId } = useParams();

    useEffect(() => {
        async function loadPatient() {
            axios.get('/api/sessions', {
                params: {
                    epilepsiaId: epilepsiaId,
                }
            }).then(res => {
                const patient = res.data.sessions[0];
                setPatient(patient);
            });
        }
        loadPatient();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function handleTypeChange(type) {
        const updatedInfo = { ...patient.epilepsy_info };
        updatedInfo.type = type;
        setPatient({ ...patient, epilepsy_info: updatedInfo });
        axios.patch('/api/session/' + patient.epilepsia_id, {
            epilepsy_info: updatedInfo
        });
    }

    function handleFocusChange(focus) {
        const updatedInfo = { ...patient.epilepsy_info };
        updatedInfo.focus = focus;
        setPatient({ ...patient, epilepsy_info: updatedInfo });
        axios.patch('/api/session/' + patient.epilepsia_id, {
            epilepsy_info: updatedInfo
        });
    }

    if (patient == null) {
        return (
            <div className='mb-3'>
                <Navigation />
                <h3 className='mt-3'>{tl.loadingPatient}</h3>
            </div>
        )
    } else {
        return (
            <div className='mb-3'>
                <Navigation />
                <h3 className='mt-3'>{tl.patient} {patient.epilepsia_id}</h3>
                <ListGroup className='mb-2'>
                    <ListGroup.Item>{tl.sessionBeginDate}: {getDateFromTimestamp(tl.timeFormat, patient.begin_ts)}</ListGroup.Item>
                    {
                        patient.end_ts != null ?
                            <ListGroup.Item>{tl.sessionEndDate}: {getDateFromTimestamp(tl.timeFormat, patient.end_ts)}</ListGroup.Item>
                            :
                            <></>
                    }
                    <ListGroup.Item>{tl.position}: {patient.position}</ListGroup.Item>
                    <ListGroup.Item>{tl.status}: {patient.status}</ListGroup.Item>
                    <ListGroup.Item>Cosinuss_GW: {patient.current_tablet.cosinuss_gw}</ListGroup.Item>
                    <ListGroup.Item>Cosinuss_PID: {patient.current_tablet.cosinuss_pid}</ListGroup.Item>
                    <ListGroup.Item>Tablet MAC: {patient.current_tablet.mac}</ListGroup.Item>
                    <ListGroup.Item>Tablet Status: {patient.current_tablet.status}</ListGroup.Item>
                    <ListGroup.Item>Tablet Battery: {patient.current_tablet.battery}%</ListGroup.Item>
                </ListGroup>
                <h4>{tl.patientSettings}</h4>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{tl.setting}</th>
                            <th>{tl.value}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {tl.epilepsyType}
                            </td>
                            <td>
                                <Dropdown onSelect={handleTypeChange}>
                                    <DropdownButton
                                        title={patient.epilepsy_info.type == null ? tl.noValue : tl[patient.epilepsy_info.type]}
                                        variant="secondary"
                                    >
                                        <Dropdown.Item eventKey={'focal'}>{tl.focal}</Dropdown.Item>
                                        <Dropdown.Item eventKey={'...'}>...</Dropdown.Item>
                                    </DropdownButton>
                                </Dropdown>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {tl.epilepsyFocus}
                            </td>
                            <td>
                                <Dropdown onSelect={handleFocusChange}>
                                    <DropdownButton
                                        title={patient.epilepsy_info.focus == null ? tl.noValue : tl[patient.epilepsy_info.focus]}
                                        variant="secondary"
                                    >
                                        <Dropdown.Item eventKey={'left'}>{tl.left}</Dropdown.Item>
                                        <Dropdown.Item eventKey={'right'}>{tl.right}</Dropdown.Item>
                                    </DropdownButton>
                                </Dropdown>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <h4>{tl.sensorSettings}</h4>
                <p>{tl.sensorUncomfortInformation}</p>
                <ExcludeSensors patient={patient} />
                <SensorHistory patient={patient} />
            </div >
        )
    }

}