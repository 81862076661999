import React, { useContext, useEffect, useState } from 'react';
import { translations } from '../../helpers/translations';
import { Table, Button } from 'react-bootstrap';
import { LanguageContext } from '../../contexts/LanguageContext';
import axios from 'axios';
import Navigation from '../../components/navigation/Navigation';
import { useParams } from 'react-router-dom';

export default function Sensor(props) {
  const tl = translations[useContext(LanguageContext).language];
  const [updates, setUpdates] = useState([]);
  const [offset, setOffset] = useState(0);
  const { mac } = useParams();
  const rowsPerPage = 20;

  useEffect(() => {
    async function loadUpdates() {
      axios.get('/api/sensorUpdates/' + mac, {
        params: {
          limit: rowsPerPage,
          offset: offset
        }
      }).then(res => {
        setUpdates(res.data.updates);
      });
    }
    loadUpdates();
  }, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

  function increaseOffset() {
    setOffset(offset + rowsPerPage);
  }

  function decreaseOffset() {
    setOffset(offset - rowsPerPage);
  }

  console.log(updates);

  return (
    <div>
      <Navigation />
      <h3 className='mt-3'>{mac} - {tl.latestUpdates}:</h3>
      <Table striped bordered hover className='mt-3'>
        <thead>
          <tr>
            <th>{tl.time}</th>
            <th>{tl.status}</th>
            <th>{tl.battery}</th>
            <th>{tl.info}</th>
          </tr>
        </thead>
        <tbody>
          {updates.map((update, index) => (
            <>
              {
                update.timestamp == null || update.status == null || update.battery == null || update.info == null ?
                  <></>
                  :
                  <tr key={index}>
                    <td>{new Intl.DateTimeFormat(tl.timeFormat, { dateStyle: 'medium', timeStyle: 'medium' }).format(new Date(update.timestamp))}</td>
                    <td>{update.status}</td>
                    <td>{update.battery}</td>
                    <td>{update.info}</td>
                  </tr>
              }
            </>
          ))}
        </tbody>
      </Table>
      <Button onClick={decreaseOffset} className='mx-3'>{tl.earlierUpdates}</Button>
      <Button onClick={increaseOffset} className='mx-3'>{tl.laterUpdates}</Button>
    </div>
  )
}