import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { translations } from '../../helpers/translations';
import LanguageDropdown from '../../components/navigation/LanguageDropdown';
import { LanguageContext } from '../../contexts/LanguageContext';
import './Login.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function Login(props) {
  const tl = translations[useContext(LanguageContext).language];
  const [showLoginErrorMessage, setShowLoginErrorMessage] = useState(false);
  const navigate = useNavigate();

  function setCookies(username, token) {
    document.cookie = 'epidash_username=' + username + '; expires=Sun, 1 Jan 2099 00:00:00 UTC; SameSite=strict; path=/';
    document.cookie = 'epidash_token=' + token + '; expires=Sun, 1 Jan 2099 00:00:00 UTC; SameSite=strict; path=/';
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;
    const res = await axios.post('/api/login', {
      username: username,
      password: password
    })
    if (res.data == null) {
      return;
    }
    if (!res.data.loginSuccessful) {
      setShowLoginErrorMessage(true);
    } else {
      setCookies(username, res.data.token);
      navigate('/');
    }
  }

  useEffect(() => {
    async function checkForRedirect() {
      const username = Cookies.get('epidash_username');
      const token = Cookies.get('epidash_token');
      if (username != null && token != null) {
        const encodedToken = encodeURIComponent(token);
        const res = await axios.get('/api/checkSession/' + username + '/' + encodedToken);
        if (res.data?.sessionValid) {
          navigate('/');
        }
      }
    }
    checkForRedirect();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="auth-form-container h-100 d-flex align-items-center justify-content-center">
        <form className="auth-form col-md-6" method="post" onSubmit={handleSubmit}>
          <div className="auth-form-content">
            <h3 className="auth-form-title d-flex align-items-center justify-content-center">EPILEPSIA</h3>
            <h4 className="auth-form-title d-flex align-items-center justify-content-center">Dashboard Login</h4>
            <div className="form-group mt-3">
              <label>{tl.username}</label>
              <input
                type="text"
                className="form-control mt-1"
                placeholder={tl.enterUsername}
                name="username"
              />
            </div>
            <div className="form-group mt-3">
              <label>{tl.password}</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder={tl.enterPassword}
                name="password"
              />
            </div>
            <div className="col-3 d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                {tl.login}
              </button>
              <LanguageDropdown loggedIn={false} className="mt-3"></LanguageDropdown>
            </div>
          </div>
        </form>
      </div>
      <div>
        {showLoginErrorMessage &&
          <Alert className="login-error-message mt-3" variant="danger">
            {tl.loginErrorMessage}
          </Alert>
        }
      </div>
    </div>
  )
}