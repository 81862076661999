import React, { useContext, useState } from 'react';
import { translations } from '../../helpers/translations';
import { LanguageContext } from '../../contexts/LanguageContext';
import Navigation from '../../components/navigation/Navigation';

export default function Tablet(props) {
  const tl = translations[useContext(LanguageContext).language];

  return (
    <div>
        <Navigation/>
    </div>
  )
}