import './Home.css';
import Navigation from '../../components/navigation/Navigation';
import Tabs from '../../components/Tabs';
import { useEffect, useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { LocationContext } from '../../contexts/LocationContext';
import { RoleContext } from '../../contexts/RoleContext';
import axios from 'axios';

function Home(props) {
  const { language, setLanguage } = useContext(LanguageContext);
  const { location, setLocation } = useContext(LocationContext);
  const { roles, setRoles } = useContext(RoleContext);

  useEffect(() => {
    async function loadPreferredSettings() {
      const res = await axios.get('/api/settings');
      if (res.data?.user != null) {
        setLanguage(res.data.user.preferredLanguage);
        setLocation(res.data.user.preferredLocation);
        setRoles(res.data.user.roles);
      }
    }
    loadPreferredSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Navigation />
      <Tabs />
    </div>
  )

}

export default Home;