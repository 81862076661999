import React, { useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { LocationContext } from '../../contexts/LocationContext';
import { translations } from '../../helpers/translations';
import axios from 'axios';
import { getDateFromTimestamp } from '../../helpers/helperFunctions';

export default function NotificationCenter() {
    const tl = translations[useContext(LanguageContext).language];
    const location = useContext(LocationContext).location;
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        async function updateNotifications() {
            const res = await axios.get('/api/messages/', {
                params: {
                    dismissed: false,
                    location: location,
                    type: 'notification'
                }
            });
            setMessages(res.data.messages);
        }
        updateNotifications();
        const intervalID = setInterval(() => {
            updateNotifications();
        }, 10000);
        return () => {
            clearInterval(intervalID);
        }
    }, [location]);

    function dismissNotification(index) {
        axios.post(`/api/message/${messages[index]._id}/dismiss`);
        setMessages(messages.filter((_, i) => i !== index));
    }

    return (
        <div>
            <Card>
                <Card.Header>
                    <h3>{tl.notificationCenter}</h3>
                </Card.Header>
                <Card.Body>
                    {messages.length === 0 ?
                        <p>{tl.noNotifications}</p>
                        :
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{tl.time}</th>
                                    <th>{tl.topic}</th>
                                    <th>{tl.message}</th>
                                    <th>{tl.dismiss}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {messages.map((message, index) => (
                                    <tr key={index}>
                                        <td>{getDateFromTimestamp(tl.timeFormat, message.timestamp)}</td>
                                        <td>{message.topic}</td>
                                        <td>{message.text}</td>
                                        <td>
                                            <Button onClick={e => dismissNotification(index)}>
                                                {tl.dismiss}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }

                </Card.Body>
            </Card>
        </div>
    );
};
