import { Row, Spinner } from 'react-bootstrap';
import PatientCard from './PatientCard/PatientCard';
import { useContext, useEffect, useState } from 'react';
import { getLowestRunningSensor } from '../../helpers/helperFunctions';
import { translations } from '../../helpers/translations';
import { LanguageContext } from '../../contexts/LanguageContext';
import { LocationContext } from '../../contexts/LocationContext';
import axios from 'axios';
import { formatTime } from '../../helpers/helperFunctions';
import NotificationCenter from '../notifications/NotificationCenter';

export default function Overview(props) {
    const tl = translations[useContext(LanguageContext).language];
    const location = useContext(LocationContext).location;
    const [sortedPatients, setSortedPatients] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function updatePatients() {
            const sessionsRes = await axios.get('/api/sessions', {
                params: {
                    status: 'running',
                    location: location
                }
            });
            const patients = sessionsRes.data.sessions;
            await Promise.allSettled(patients.map(async (patient, index) => {
                const patientTabsOffset = 2;
                patient.tabIndex = index + patientTabsOffset;
                return axios.get('/api/sensors', {
                    params: {
                        epilepsiaId: patient.epilepsia_id
                    }
                }).then(res => {
                    patient.current_sensors = res.data.sensors;
                    for (const sensor of res.data.sensors) {
                        formatTime(tl.timeFormat, sensor.latestUpdates[0]);
                    }
                });
            }));
            const sortedPatients = [...patients];
            sortedPatients.sort((p1, p2) => {
                const p1Lowest = getLowestRunningSensor(p1);
                const p2Lowest = getLowestRunningSensor(p2);
                if (p1Lowest == null && p2Lowest == null) {
                    return 0;
                } else if (p1Lowest == null && p2Lowest != null) {
                    return 1;
                } else if (p1Lowest != null && p2Lowest == null) {
                    return -1;
                } else {
                    if (p1Lowest.remainingRunningTime == null && p2Lowest.remainingRunningTime != null) {
                        return 1;
                    } else if (p2Lowest.remainingRunningTime == null && p1Lowest.remainingRunningTime != null) {
                        return -1;
                    } else {
                        return p1Lowest.remainingRunningTime - p2Lowest.remainingRunningTime;
                    }
                }
            });
            setSortedPatients(sortedPatients);
            setLoading(false);
        }
        updatePatients();
        const intervalID = setInterval(() => {
            updatePatients();
        }, 5000);
        return () => {
            clearInterval(intervalID);
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    } else {
        return (
            <div>
                <Row>
                    {sortedPatients.map((patient, index) => (
                        <PatientCard handleClick={props.handleClick} key={index} patient={patient} />
                    ))}
                </Row>
                <hr />
                <Row>
                    <NotificationCenter />
                </Row>
                <hr />
            </div>
        );
    }

};