import React, { useContext, useEffect, useState } from 'react';
import { translations } from '../../helpers/translations';
import { LanguageContext } from '../../contexts/LanguageContext';
import axios from 'axios';
import { Spinner, Table } from 'react-bootstrap';
import { getDateFromTimestamp } from '../../helpers/helperFunctions';
import { DataGrid } from '@mui/x-data-grid';

export default function SensorHistory(props) {
    const tl = translations[useContext(LanguageContext).language];
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const patient = props.patient;

    async function handleRefreshClick() {
        setLoading(true);
        await updateHistory();
        setLoading(false);
    }

    async function updateHistory() {
        const res = await axios.get('/api/patientHistory', {
            params: {
                epilepsiaId: patient.epilepsia_id
            }
        });
        setHistory(res.data.history);
    }

    useEffect(() => {
        async function initalLoad() {
            await updateHistory();
            setLoading(false);
        }
        initalLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function getColumns() {
        return [
            { field: 'sensorName', headerName: tl.sensorName, width: 150 },
            { field: 'mac', headerName: tl.mac, width: 150 },
            { field: 'type', headerName: tl.type, width: 150 },
            { field: 'bodyPosition', headerName: tl.bodyPosition, width: 100 },
            { field: 'connectedAt', headerName: tl.connectedAt, width: 300 },
            { field: 'disconnectedAt', headerName: tl.disconnectedAt, width: 300 },
        ];
    }

    function getCurrentRows() {
        let i = 1;
        const rows = [];
        for (const e of history) {
            rows.push({
                id: i,
                sensorName: e.name,
                mac: e.mac,
                type: e.type,
                bodyPosition: e.bodyPosition == null ? tl.unknown : e.bodyPosition,
                connectedAt: getDateFromTimestamp(tl.timeFormat, e.start_timestamp),
                disconnectedAt: getDateFromTimestamp(tl.timeFormat, e.end_timestamp),
            });
            i++;
        }
        return rows;
    }

    if (loading) {
        return <Spinner></Spinner>
    } else {
        return (
            <div className='mt-3'>
                <h4>{tl.sensorHistory}</h4>
                <DataGrid
                    rows={getCurrentRows()}
                    columns={getColumns()}
                    on
                />
            </div>
        )
    }

}