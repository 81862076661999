import { Col, Card } from 'react-bootstrap';
import './PatientCard.css';
import { translations } from '../../../helpers/translations';
import { getTimeUntilSensorSwitch, getDateFromTimestamp, getVariantForSensor } from '../../../helpers/helperFunctions';
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';

export default function PatientCard(props) {
    const tl = translations[useContext(LanguageContext).language];
    const patient = props.patient;
    let maxSensors = 5;
    if (patient.excluded_sensors != null) {
        maxSensors = maxSensors - props.patient.excluded_sensors.length;
    }

    function getPatientStatusSummary() {
        if (patient.current_sensors.length === 0) {
            return tl.noSensorsConnected;
        } else if (patient.current_sensors.length < maxSensors) {
            return patient.current_sensors.length + ' ' + tl.of + ' ' + maxSensors + ' ' + tl.sensorsConnected;
        }
        for (const sensor of patient.current_sensors) {
            if (sensor.latestUpdate == null || sensor.latestUpdate.status === 'error' || sensor.latestUpdate.status === 'disconnected') {
                return tl.problemWithSensor + ' ' + sensor.name;
            }
        }
        for (const sensor of patient.current_sensors) {
            if (sensor.remainingRunningTime < 30) {
                return tl.sensor + ' ' + sensor.name + ' ' + tl.needsToBeChanged;
            }
        }
        return patient.current_sensors.length + ' ' + tl.of + ' ' + maxSensors + ' ' + tl.sensorsConnected;
    }

    function getVariant() {
        if (patient.current_sensors.length < maxSensors) {
            return 'danger';
        }
        for (const sensor of patient.current_sensors) {
            if (getVariantForSensor(sensor) === 'danger') {
                return 'danger';
            }
        }
        return 'success';
    }

    function handleClick() {
        props.handleClick(null, (patient.tabIndex.toString()));
    }

    const variant = getVariant();
    return (
        <Col key={patient.id} md={6} lg={4}>
            <Card className="mb-4 patient-card" border={variant} bg={variant} text='white'>
                <Card.Body onClick={handleClick}>
                    <Card.Title>{tl.patient + ' ' + patient.position}</Card.Title>
                    <Card.Text>
                        <strong>{tl.patient} ID:</strong> {patient.epilepsia_id}
                    </Card.Text>
                    <Card.Text>
                        <strong>{tl.sessionStartedAt}:</strong> {getDateFromTimestamp(tl.timeFormat, patient.begin_ts)}
                    </Card.Text>
                    <Card.Text>
                        <strong>{tl.status}:</strong> {getPatientStatusSummary()}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );

}
